<template>
  <div class="card card-body shadow-sm">
    <div class="manage-status-facilities">
      <form novalidate="novalidate" autocomplete="off" v-on:submit.prevent="createOrUpdate">
        <div class="manage-status-form">
          <div class="app-table-container mb-3">
            <div class="table-responsive">
              <table class="table app-table-list">
                <thead class="thead-light">
                <tr>
                  <th scope="col" style="width: 10%;">
                    時間
                  </th>
                  <th scope="col" style="width: 70%;">
                    混雑状況
                  </th>
                  <th scope="col" style="width: 20%;">
                    混雑状況更新
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item, key) in data">
                  <td class="text-center" style="vertical-align: middle;">
                    {{item.hour}}
                  </td>
                  <td>
                    <div class="row d-flex text-center">
                      <div class="col icheck-primary">
                        <input class="form-check-input input-status" @change="inputChange(item.id, key)" type="radio" :name="item.id"
                               :id="'statusRadioFree' + item.id" value="1" :checked="item.status == 1 ? 'checked' : ''" :disabled="item.manage_type == 1 ? true : false">
                        <label class="form-check-label" :for="'statusRadioFree' + item.id">空いています</label>
                      </div>
                      <div class="col icheck-primary">
                        <input class="form-check-input input-status" @change="inputChange(item.id, key)" type="radio" :name="item.id"
                               :id="'statusRadioSlightlyCrowded' + item.id" value="2"
                               :checked="item.status == 2 ? 'checked' : ''" :disabled="item.manage_type == 1 ? true : false">
                        <label class="form-check-label" :for="'statusRadioSlightlyCrowded' + item.id">やや混んでいます</label>
                      </div>
                      <div class="col icheck-primary">
                        <input class="form-check-input input-status" @change="inputChange(item.id, key)" type="radio" :name="item.id"
                               :id="'statusRadioCrowded' + item.id" value="3"
                               :checked="item.status == 3 ? 'checked' : ''" :disabled="item.manage_type == 1 ? true : false">
                        <label class="form-check-label" :for="'statusRadioCrowded' + item.id">混んでいます</label>
                      </div>
                      <div class="col icheck-primary">
                        <input class="form-check-input input-status" @change="inputChange(item.id, key)" type="radio" :name="item.id"
                               :id="'statusRadioOffHour' + item.id" value="0"
                               :checked="item.status == 0 ? 'checked' : ''" :disabled="item.manage_type == 1 ? true : false">
                        <label class="form-check-label" :for="'statusRadioOffHour' + item.id">営業時間外</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="d-flex text-center">
                      <div class="col icheck-primary">
                        <input class="form-check-input" @change="inputChange(item.id, key)" type="radio"
                               :name="'manageType' + item.id" :id="'manageTypeAutomatic' + item.id" value="1"
                               :checked="item.manage_type == 1 ? 'checked' : ''">
                        <label class="form-check-label" :for="'manageTypeAutomatic' + item.id">自動</label>
                      </div>
                      <div class="col icheck-primary">
                        <input class="form-check-input" @change="inputChange(item.id, key)" type="radio"
                               :name="'manageType' + item.id" :id="'manageTypeManual' + item.id" value="0"
                               :checked="item.manage_type == 0 ? 'checked' : ''">
                        <label class="form-check-label" :for="'manageTypeManual' + item.id">手動</label>
                      </div>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="card-footer row justify-content-end" >
            <button type="button" class="btn btn-default m-r-10" @click="handlerCancelButton">
              {{ $t('facilities.back') }}
            </button>
            <button class="btn btn-primary" v-if="data.length" type="submit">
              {{ $t('common.save') }}
            </button>
          </div>
        </div>
      </form>
    </div><!-- end .manage-status-facilities -->
  </div>
</template>
<script>
  import AppBasicTable from "@components/_common/list/AppBasicTable";
  import AppDateRangePicker from "../../../components/forms/AppDateRangepicker";
  import {ENDPOINT} from "../../../constants/api";
  import {FACILITIES_CONSTANT} from "../../../constants/facilities";
  import {ROUTES} from "@constants";

  export default {
    name: "FacilityManageStatus",
    components: {
      "app-basic-table": AppBasicTable,
      AppDateRangePicker,
    },
    data() {
      const id = this.$route.params.id
      const type = this.$route.params.type
      return {
        data: {},
        entry: {},
        idEditing : id,
      };
    },
    methods: {
      inputChange(entryId, key) {
        this.entry[key].status = $("input[name='" + entryId + "']:checked").val()
        this.entry[key].manage_type = $("input[name='manageType" + entryId + "']:checked").val()

        if (this.entry[key].manage_type == 1) {
          $("input[name='" + entryId + "']").attr('disabled', true);
        } else {
          $("input[name='" + entryId + "']").attr('disabled', false);
        }
      },
      async createOrUpdate() {

        let res = null;
        let msg = "";
        res = await this.$request.patch(this.ENDPOINT.FACILITY_MANAGE_STATUS_UPDATE(this.idEditing), this.entry);
        msg = this.$t("common.msg update ok")

        if (res.hasErrors()) {
          this.__noticeError(res.data.error_msg != undefined ? res.data.error_msg : this.$t('common.error'));
          if (res.status === 'error') {
            this.logoutAction()
          }
        } else {
          this.__noticeSuccess(msg);
          this.redirectDefaultValue();
        }
      },
      handlerCancelButton() {
        if(this.$store.state.setting.prevRouter.name === ROUTES.ADMIN.FACILITY_LIST) {
          this.__redirectBack()
        } else {
          this.redirectDefaultValue()
        }
      },
      redirectDefaultValue() {
        this.$router.push({
            name: this.ROUTES.ADMIN.FACILITY_LIST,
            query : {
              'filters.status_disp_crowded.equal' : 'all',
              'perPage' : '99999'
            }
          });
      },
    },
    mounted: function () {
      this.$request.get(this.ENDPOINT.FACILITY_MANAGE_STATUS, {facility_id : this.idEditing}).then(res => {
        if (res.hasErrors()) {
          if (res.status === 'error'){
            this.__noticeError(this.$t(res.data.error_msg));
          }
          this.redirectDefaultValue();
        } else {
          this.data = res.data.data
          this.entry = res.data.data
        }
      })
    },
  }
</script>
